<template>
  <div class="page_box">
    <div class="box_top">
      <Header class="page_hea" />

      <!-- 立即购买上部tab -->
      <div class="but_top">
        <div
          v-for="(item, index) in tarButList"
          :key="index"
          :class="`${butActive==index?'but_act':''} but_con but_${item.class}`"
          @click="tarBut(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="box_o">
      <!-- <div class="title_o"
           v-if="butActive==0">
        <div class="tit_con"
             :class="{'tit_act':!titActive}"
             @click="tarTit(0)">按License购买</div>
        <div class="tit_con"
             :class="{'tit_act':titActive}"
             @click="tarTit(1)">按时长购买</div>
      </div> -->
      <div
        v-if="butActive==1"
        class="title_o"
      >
        <div class="tit_cona">
          SaaS版本购买
        </div>
      </div>
      <!-- <div class="title_o"
           v-if="butActive==2">
        <div class="tit_cona">服务包</div>
      </div> -->
      <div
        v-if="butActive==3"
        class="title_o"
      >
        <div class="tit_cona">
          服务产品购买
        </div>
      </div>
      <!-- 旗舰版购买 & 服务包的列表 0&2 -->
      <div
        v-if="butActive==0||butActive==2"
        class="chart_con"
      >
        <div
          v-for="(item, tindex) in tableList"
          :key="tindex"
        >
          <div
            v-show="butActive == item.show"
            class="title_o"
          >
            <div class="tit_cona">
              {{ item.title }}
            </div>
          </div>
          <!-- 旗舰版购买的查看功能列表 -->
          <div
            v-if="butActive==0 && tindex == 0"
            class="title_more"
            @click="goMore"
          >
            查看功能列表>>
          </div>
          <div
            v-show="butActive == item.show"
            class="chart_th"
          >
            <div
              v-for="titItem in item.header"
              :key="titItem.id+'t'"
              :style="titItem.style"
              class="chart_hea"
            >
              <img
                v-if="titItem.img"
                :src="titItem.img"
                alt=""
              >
              {{ titItem.name }}
            </div>
          </div>
          <div
            v-for="(liItem ,index) in item.data"
            v-show="butActive == item.show"
            :key="index+'l'"
          >
            <div class="chart_lic">
              <div
                v-for="dataItem in liItem"
                :key="dataItem.id"
                :style="dataItem.style"
              >
                <span
                  v-if="dataItem.name=='咨询'"
                  class="chart_act"
                  @click="openBut"
                >{{ dataItem.name }}</span>
                <div
                  v-else-if="dataItem.img"
                  class="chart_imgC"
                >
                  <img
                    :src="dataItem.img"
                    alt=""
                  >
                  <span
                    :style="dataItem.sty"
                    @click="openService"
                  >{{ dataItem.name }}</span>
                </div>
                <span v-else>{{ dataItem.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <btn
          :content="'马上咨询'"
          @click="handleBtn"
        />
      </div>
      <!-- 服务购买的列表 3 -->
      <div
        v-if="butActive==3"
        class="serve_buy"
      >
        <div
          v-for="items in severData"
          :key="items.id+'c'"
          class="list_con"
        >
          <div>
            <img
              :src="items.imgUrl"
              alt=""
            >
          </div>
          <div class="list_data">
            <div>
              <span class="tit_con">{{ items.title }}</span><br>
              <div
                v-for="(arge,index) in items.data"
                :key="index"
              >
                <span>{{ arge }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SaaS版本购买的表单 1 -->
      <div
        v-if="butActive==1"
        class="from_con"
      >
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="70px"
          class="demo-ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="姓名"
                prop="name"
              >
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入您的姓名"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="职务">
                <el-input
                  v-model="ruleForm.duty"
                  placeholder="请输入您的职务"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="12">
              <el-form-item
                label="电话"
                prop="phone"
              >
                <el-input
                  v-model="ruleForm.phone"
                  placeholder="请输入联系电话"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱">
                <el-input
                  v-model="ruleForm.email"
                  placeholder="请输入邮箱地址"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20 el_li"
            :gutter="20"
          >
            <el-col :span="24">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="地址"
                    prop="provinceValue"
                  >
                    <el-select
                      v-model="ruleForm.provinceValue"
                      placeholder="省份"
                      @change="provinceChange"
                    >
                      <el-option
                        v-for="(item,index) in province"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    class="text-center"
                    prop="cityValue"
                  >
                    <el-select
                      v-model="ruleForm.cityValue"
                      :disabled="cityStatus"
                      placeholder="城市"
                      @change="cityChange"
                    >
                      <el-option
                        v-for="(item,index) in city"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="countyValue">
                    <el-select
                      v-model="ruleForm.countyValue"
                      :disabled="countyStatus"
                      placeholder="区县"
                      @change="countyChange"
                    >
                      <el-option
                        v-for="(item,index) in county"
                        :key="index"
                        :label="item.label"
                        style="padding: 0 20px"
                        :value="index"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item label="公司">
                <el-input
                  v-model="ruleForm.company"
                  placeholder="请输入公司名称"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            class="mt-20"
            :gutter="20"
          >
            <el-col :span="24">
              <el-form-item label="其他">
                <el-input
                  v-model="ruleForm.more"
                  type="textarea"
                  :rows="5"
                  placeholder="其他您想要告诉我们的"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <div
            class="buttonShen"
            @click="submitForm('ruleForm')"
          >
            申请购买
          </div>
        </el-form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script >
import mock from '@/assets/js/mock.js';
import areac from '@/assets/js/areac';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import img_top from '@/assets/imgs/buying/img_top.png';
import btn from '@/components/btn.vue';

export default {
  name: 'BuyingPage',
  components: {
    Header,
    Footer,
    btn,
  },
  data() {
    return {
      tarButList: [
        {
          class: 'o',
          name: 'JEPaaS购买',
        },
        {
          class: 's',
          name: 'SaaS版本购买',
        },
        {
          class: 't',
          name: '服务包',
        },
        {
          class: 'f',
          name: '服务产品购买',
        },
      ],
      prohibit: true,
      province: [],
      provinceIndex: null, // 省的下标
      city: [],
      cityIndex: null, // 城市的下标
      cityStatus: true,
      county: [],
      countyStatus: true,
      countyIndex: null, // 区县的下标
      /** ******************表单的验证************** */
      ruleForm: {
        name: '',
        duty: '',
        phone: '',
        email: '',
        company: '',
        more: '',
        provinceValue: '',
        provinceCode: '',
        cityValue: '',
        cityCode: '',
        countyValue: '',
        address: '',
        areaCode: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入您的联系电话', trigger: 'blur' },
        ],
        provinceValue: [
          { required: true, message: '请选择您的省份', trigger: 'change' },
        ],
        cityValue: [
          { required: true, message: '请选择您的城市', trigger: 'change' },
        ],
        countyValue: [
          { required: true, message: '请选择您的区县', trigger: 'change' },
        ],
      },
      img_top,
      butActive: 0,
      titActive: 0,
      headerData: [],
      conData: {},
      tableList: [
        {
          title: '标准版购买',
          header: mock.buying.licensetit,
          data: mock.buying.licensedata,
          show: 0,
        },
        {
          title: '企业版购买',
          header: mock.buying.timtit,
          data: mock.buying.timdata,
          show: 0,
        },
        {
          title: '服务包',
          header: mock.buying.severPacktit,
          data: mock.buying.severPackdata,
          show: 2,
        },
      ],
      severData: mock.buying.severData,
      licensetit: mock.buying.licensetit,
      licensedata: mock.buying.licensedata,
      timtit: mock.buying.timtit,
      timdata: mock.buying.timdata,
    };
  },
  created() {
    // const self = this
    this.getCity();
    this.prohibit = true;
    // self.headerData = mock.buying.licensetit;
    // self.arrData = mock.buying.licensedata;
    // this.severData = mock.buying.severData;
  },
  methods: {
    // 马上咨询
    handleBtn() {
      this.$store.commit('addActChange', false);
      this.$store.commit('moreActChange', false);
      this.$store.commit('winActChange', true);
    },
    goMore() {
      const self = this;
      self.$store.commit('moreActChange', true);
      self.$store.commit('winActChange', true);
      self.$store.commit('addActChange', false);
    },
    openService() {
      const self = this;
      window.scrollTo(0, 0);
      self.tarBut(2);
    },
    openBut() {
      const self = this;
      self.$store.commit('addActChange', false);
      self.$store.commit('moreActChange', false);
      self.$store.commit('winActChange', true);
    },
    tarBut(e) {
      const self = this;
      self.butActive = e;
      if (e == 0) {
        self.tarTit(e);
      }
      e == 2 ? self.arrData = mock.buying.severPackdata : self.arrData = mock.buying.licensedata;
      e == 2 ? self.headerData = mock.buying.severPacktit : self.headerData = mock.buying.licensetit;
    },
    tarTit(e) {
      const self = this;
      self.titActive = e;
      e == 1 ? self.arrData = mock.buying.timdata : self.arrData = mock.buying.licensedata;
      e == 1 ? self.headerData = mock.buying.timtit : self.headerData = mock.buying.licensetit;
    },
    toggTab(e) {
      const self = this;
      self.indexS = e.id;
      self.listArr.filter(item => (e.id === item.id ? item.active = true : item.active = false));
    },
    areaNo() {
      this.addressShow = false;
    },
    // 注册地址选择成功
    areaOk(data) {
      const self = this;
      let city = '';
      data.forEach((val, index) => {
        if (index !== 2) {
          city += `${val.name},`;
        } else {
          city += `${val.name}`;
        }
      });
      self.ruleForm.provinceValue = data[0].name;
      self.ruleForm.provinceCode = data[0].code;
      self.ruleForm.cityValue = data[1].name;
      self.ruleForm.cityCode = data[1].code;
      self.ruleForm.countyValue = data[2].name;
      self.ruleForm.areaCode = data[2].code;
      this.ruleForm.address = city;
      this.addressShow = false;
    },
    // 点击选中县城
    countyChange(val) {
      this.ruleForm.countyValue = this.county[val].label;
      this.ruleForm.areaCode = this.county[val].value;
    },
    // 点击选中市
    cityChange(val) {
      this.cityIndex = val;
      this.ruleForm.countyValue = '';
      this.ruleForm.areaCode = '';
      this.county = this.province[this.provinceIndex].children[val].children;
      this.countyStatus = false;
      this.ruleForm.cityValue = this.city[val].label;
      this.ruleForm.cityCode = this.city[val].value;
    },
    // 点击选中省
    provinceChange(val) {
      this.provinceIndex = val;
      this.county = '';
      this.ruleForm.cityValue = '';
      this.ruleForm.countyValue = '';
      this.ruleForm.cityCode = '';
      this.ruleForm.areaCode = '';
      this.city = this.province[val].children;
      this.cityStatus = false;
      this.ruleForm.provinceValue = this.province[val].label;
      this.ruleForm.provinceCode = this.province[val].value;
    },
    // 获取三级联查的各个地区
    getCity() {
      this.province = areac.data.filter(item => [
        item.value,
        item.label,
      ]);
    },
    // 马上加入
    submitForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (self.prohibit) {
            self.prohibit = false;
            self.$ajax({
              url: '/je/product/crm/customer/saveSaleLead',
              data: {
                typeCode: 'jepaas',
                name: self.ruleForm.name,
                telePhone: self.ruleForm.phone,
                corporateName: self.ruleForm.company,
                provinceName: self.ruleForm.provinceValue,
                provinceCode: self.ruleForm.provinceCode,
                cityName: self.ruleForm.cityValue,
                cityCode: self.ruleForm.cityCode,
                areaName: self.ruleForm.countyValue,
                areaCode: self.ruleForm.areaCode,
                duties: self.ruleForm.duty,
                email: self.ruleForm.email,
                describe: self.ruleForm.more,
              },
            }).then(() => {
              self.prohibit = true;
              this.$message.success('提交成功请静候佳音!');
              self.ruleForm.name = '';
              self.ruleForm.phone = '';
              self.ruleForm.company = '';
              self.ruleForm.provinceValue = '';
              self.ruleForm.provinceCode = '';
              self.ruleForm.cityValue = '';
              self.ruleForm.cityCode = '';
              self.ruleForm.countyValue = '';
              self.ruleForm.areaCode = '';
              self.ruleForm.duty = '';
              self.ruleForm.email = '';
              self.ruleForm.more = '';
              this.$refs[formName].resetFields();
            }).catch(() => {

            });
          }
        } else {
          this.$message.error('请将所有必填信息填写完整');
          return false;
        }
      });
    },
  },
};
</script>

<style lang='less' scoped >
.page_box {
  .box_top {
    width: 100%;
    height: 80px;
    position: relative;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
    display: flex;
    justify-content: space-between;
    .top_tlite {
      padding: 160px 0 0 195px;
      font-size: 40px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
    }
    .top_img {
      img {
        width: 882px;
        height: 100%;
      }
    }
    .but_top {
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #fff;
      border-bottom-color: transparent;
      margin-bottom: -1px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .but_con {
        width: 200px;
        height: 60px;
        line-height: 60px;
        margin-bottom: -1px;
        color: #fff;
        border-right: 2px solid #fff;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }
      .but_o {
        border-top-left-radius: 10px;
      }
      .but_f {
        border-right: 0px;
        border-top-right-radius: 10px;
      }
      .but_act {
        background-color: #fff;
        color: #ff3041;
      }
    }
  }
  .box_o {
    margin-bottom: 120px;
    .title_more {
      width: 1200px;
      margin: 0 auto;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 151, 201, 1);
      text-align: right;
      cursor: pointer;
    }
    .title_o {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin: 120px 0 60px 0;
      .tit_con {
        cursor: pointer;
        font-size: 30px;
        font-weight: bold;
        color: #666;
        margin: 0 30px;
        padding-bottom: 10px;
        border-bottom: 4px solid transparent;
      }
      .tit_cona {
        font-size: 30px;
        font-weight: bold;
        color: #000;
        margin: 0 30px;
        padding-bottom: 10px;
        border-bottom: 4px solid transparent;
      }
      .tit_act {
        color: #e38a34;
        border-bottom: 4px solid #e38a34;
      }
    }
    .margin_top {
      margin-top: 220px !important;
    }
    .chart_con {
      width: 1200px;
      margin: 0 auto;
      .chart_th {
        display: flex;
        align-items: center;
        justify-content: center;
        .chart_hea {
          width: 180px;
          line-height: 100px;
          margin-right: -2px;
          margin-bottom: -2px;
          font-size: 19px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 28px;
            height: 28px;
          }
        }
      }
      .chart_lic {
        // height:70px;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 70px;
          border: 1px solid #efefef;
          font-size: 17px;
          margin-right: -2px;
          margin-top: -2px;
          margin-bottom: -2px;
          padding: 0 10px;
        }
        .div_padding {
          padding: 0 10px;
        }
        .chart_act {
          color: #356bd1;
          cursor: pointer;
          border-bottom: 1px solid transparent;
          transition: border-bottom 0.7s;
        }
        .chart_act:hover {
          border-bottom: 1px solid #356bd1;
        }
        .chart_imgC {
          cursor: pointer;
          border: 0px;
          width: 100%;
          img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
  .serve_buy {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .list_con {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      width: 580px;
      height: 240px;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      box-shadow: 4px 4px 6px 1px #eeeeee;
      img {
        width: 290px;
        height: 240px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .list_data {
        width: 290px;
        height: 240px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          font-size: 16px;
        }
        .tit_con {
          font-size: 24px;
          font-weight: bold;
          line-height: 60px;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
  }
  .from_con {
    width: 950px;
    height: 680px;
    margin: 60px auto;
    padding: 100px 90px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: left;
    .mt-20 {
      margin-top: 20px;
    }
    .buttonShen {
      cursor: pointer;
      width: 480px;
      margin: 90px auto;
      height: 60px;
      background: rgba(255, 48, 65, 1);
      box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
      border-radius: 30px;
      line-height: 60px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(254, 255, 255, 1);
      text-align: center;
      position: relative;
    }
    .buttonShen::after {
      content: "";
      width: 480px;
      height: 60px;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 30px;
      z-index: -1;
      transition: background 0.7s;
    }
    .buttonShen:hover:after {
      z-index: 2;
      background: rgba(51, 51, 51, 0.1);
    }
  }
}
</style>
